
















import { Component, Vue } from 'vue-property-decorator'
import Header from './Header.vue'
@Component({
  name: 'layoutIndex',
  components: {
    Header,
  },
})
export default class Index extends Vue {
  private flag = true
  private reload() {
    this.flag = false
    this.$nextTick(() => {
      this.flag = true
    })
  }
}
