
















































































































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { Dropdown, DropdownMenu, DropdownItem, Icon } from 'view-design'
import emitter from '../taint/Emitter'
import { formatTimestamp } from '../../utils/utils'
@Component({
  name: 'layoutHeader',
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Icon,
  },
})
export default class Header extends VueBase {
  private logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
  private logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  changelogo() {
    this.logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
    this.logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  }
  private goPath(str: string) {
    if (str) {
      window.open(str)
    }
  }
  private fmtStr(timestamp: number | any) {
    return formatTimestamp(timestamp)
  }
  private show = false
  private async changeLanguage(language: string) {
    const userInfo: any = this.userInfo
    const res = await this.services.setting.setLang(language)
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    window.localStorage.setItem(userInfo.username + '-language', language)
    switch (language) {
      case 'zh':
        this.$i18n.locale = 'zh_cn'
        break
      case 'en':
        this.$i18n.locale = 'en'
        break
      default:
        this.$i18n.locale = 'zh_cn'
        break
    }
    if (this.$route && this.$route.meta) {
      document.title = this.$t(this.$route.meta.i18n) as string
    }
    this.$emit('reload')
  }
  get userInfo(): { role: number } {
    return this.$store.getters.userInfo
  }
  async buildIAST() {
    this.$router.push('/deploy')
  }

  async messageList() {
    const res = await this.services.message.list({
      page_size: this.mSize,
      page: this.num_pages,
    })
    console.log(res)
  }
  private count = 0
  async messageUnreadCount() {
    if (!this.userInfo) {
      return
    }
    const res = await this.services.message.unreadCount()
    if (res.status === 201) {
      this.count = res.data.new_message_count
    } else {
      this.$message.error(res.msg)
    }
  }

  private mList = []
  private mTotal = 0
  private mLoading = false
  private num_pages = 1
  private mSize = 999
  private showPop = false
  private async deleteMessage(id: number) {
    this.$confirm(
      this.$t('message.warning') as string,
      this.$t('message.tips') as string,
      {
        confirmButtonText: this.$t('message.confirm') as string,
        cancelButtonText: this.$t('message.cancel') as string,
        type: 'warning',
      }
    ).then(async () => {
      let res
      if (id) {
        res = await this.services.message.mDelete({
          id: id,
          all: false,
        })
      } else {
        res = await this.services.message.mDelete({
          all: true,
        })
      }
      if (res.status === 201) {
        this.showMessage()
        this.messageUnreadCount()
      } else {
        this.$message.error(res.msg)
      }
      this.showPop = true
    })
  }
  handleCurrentChange(val: number) {
    this.num_pages = val
    this.showMessage()
  }
  async showMessage() {
    this.mLoading = true
    const res = await this.services.message.list({
      page_size: this.mSize,
      page: this.num_pages,
    })
    await this.messageUnreadCount()
    this.mLoading = false
    if (res.status === 201) {
      this.mList = res.data.messages
      this.mTotal = res.data.page.alltotal
    } else {
      this.$message.error(res.msg)
    }
  }

  canShow(name: string) {
    return this.$store.getters.routers.includes(name)
  }
  private currentRoute(name: string): boolean {
    return this.$route.matched.some((item) => item.name === name)
  }

  private async logOut() {
    await this.$store.dispatch('user/logOut')
  }
  private timer: any = null

  get sca_setup(): any {
    return this.$store.state.user.userInfo?.sca_setup
  }

  created() {
    this.messageUnreadCount()
    this.timer = setInterval(() => {
      this.messageUnreadCount()
    }, 60 * 1000)
    emitter.on('changelogo', this.changelogo)
  }
}
